<template>
    <div v-if="video">
        <div class="app-video-area pb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="app-intro-video-box">
                            <img class="app-intro-video-box-image" :src="fetchImage(video.image.data.attributes.url)" alt="video-img">
                            <div 
                                v-if="video.videoLink"
                                class="video-btn"
                                v-on:click="isPopupMethod(isPopup)"
                                style="cursor: pointer"
                            >
                                <img src="@/assets/images/video/play_btn.svg" alt="play-btn">
                            </div>

                            <div class="pacifier-one">
                                <img src="@/assets/images/pacifier.svg" alt="pacifier">
                            </div>
                            <div class="pacifier-two">
                                <img src="@/assets/images/pacifier.svg" alt="pacifier">
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="app-intro-video-content">
                            <h2 class="sub-title">{{ video.subtitle }}</h2>
                            <h2>{{ video.heading }}</h2>
                            <p>{{ video.desc }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe :src="video.videoLink"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Video',
    data (){
        return {
            video: null,
            isPopup: false
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
        fetchImage(imgUrl) {
            return `${this.$baseUrl}${imgUrl}`
        },
    },
    created: async function (){
        const response = await axios.get(`${this.$baseUrl}/api/video?populate=deep`)
        const { data: {attributes} } = response.data
        this.video = attributes
    },
}
</script>

<style scoped>
.app-video-area {
    margin-top: 6rem;
}

.app-intro-video-box-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}
@media only screen and (min-width: 992px) {
    .app-video-area {
        margin-top: 2rem;
    }
}

.pacifier-one {
    position: absolute;
    animation: movebounce 5s linear infinite;
    top: -7rem;
    left: 0;
}

.pacifier-two {
    position: absolute;
    animation: movebounce 5s linear infinite;
    top: -0.7rem;
    left: -2rem;
}

.pacifier-two > img {
    transform: rotate(150deg);
}

.video-btn {
    user-select: none;
}
</style>