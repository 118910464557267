<template>
    <div class="privacy-policy-area ptb-100">
        <div class="container">
            <div class="privacy-policy-content">
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: 36pt;"><b><span
                            style="color: rgb(0, 0, 0);font-size: 26pt;">Privacy policy</span></b></p>
                <p></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">Last updated: 01-09-2023</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">This Privacy Policy describes the policies and
                        procedures on the collection, use and disclosure of your information when you use The Doula App and
                        tells you about the rights you have regarding your privacy.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">The Doula App is there to guide you through the final
                        stage of the pregnancy. We use your personal data to provide our services and improve it. By using
                        the The Doula App, you agree to the collection and use of information in accordance with this
                        Privacy Policy.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><strong><span
                            style="color: rgb(0, 0, 0);font-size: 12pt;">Collecting Your Personal Data</span></strong></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">While using The Doula App, we may ask you to provide us
                        with certain personally identifiable information that can be used to contact or identify you.
                        Personally identifiable information may include, but is not limited to:</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">Usage data and crashlytics: When you access The Doula
                        app by or through a mobile device, we may collect certain information automatically, including, but
                        not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of
                        your mobile device, your mobile operating system, the pages of The Doula App that you visit, the
                        time and date of your visit, the time spent on those pages, unique device identifiers and other
                        diagnostic data.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">For the collection of Usage Data and crashlytics we use
                        Firebase. Below you will find the Privacy Policy fort he use of these services:</span></p>
                <ul style="list-style-type: none;text-align: start;color: rgb(0, 0, 0);font-size: medium;">
                    <li style="text-align: left;color: rgb(0, 0, 0);font-size: 12pt;"><span
                                style="color: rgb(5, 99, 193);font-size: 12pt;"><a target="_blank"
                                    href="https://www.google.com/url?q=https://firebase.google.com/support/privacy/&sa=D&source=editors&ust=1695997037257018&usg=AOvVaw15B2UZbx9ktD5ZSn-FKq8C"
                                    style="color: inherit;">Firebase</a></span></li>
                </ul>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><strong><span
                            style="color: rgb(0, 0, 0);font-size: 12pt;">Use of Your Personal Data</span></strong></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">We may use Personal Data for the following
                        purposes:</span></p>
                <ul>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">To provide and maintain The Doula App, including
                            to monitor the usage of the app.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">For the performance of a contract: the
                            development, compliance and undertaking of the purchase contract for the products, items or
                            services you have purchased or of any other contract with us through The Doula App.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">For other purposes: We may use your information
                            for other purposes, such as data analysis, identifying usage trends, determining the
                            effectiveness of our promotional campaigns and to evaluate and improve our The Doula App,
                            products, services, marketing and your experience.</span></li>
                </ul>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">We may share your personal information in the following
                        situations:</span></p>
                <ul>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">With Service Providers: We may share your
                            personal information with service providers to monitor and analyze the use of The Doula
                            App.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">For business transfers: We may share or transfer
                            your personal information in connection with, or during negotiations of, any merger, sale of
                            company assets, financing, or acquisition of all or a portion of our business to another
                            company.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">With Affiliates: we may share your information
                            with our affiliates, in which case we will require those affiliates to honor this Privacy
                            Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners
                            or other companies that We control or that are under common control with us.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">With business partners: We may share your
                            information with our business partners to offer you certain products, services or
                            promotions.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">With other users: when you share personal
                            information or otherwise interact in the public areas with other users, such information may be
                            viewed by all users and may be publicly distributed outside.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">With your consent: We may disclose your personal
                            information for any other purpose with your consent.</span></li>
                </ul>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><strong><span
                            style="color: rgb(0, 0, 0);font-size: 12pt;">Retention of Your Data</span></strong></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">We will retain your Personal Data only for as long as
                        is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal
                        Data to the extent necessary to comply with our legal obligations (for example, if we are required
                        to retain your data to comply with applicable laws), resolve disputes, and enforce our legal
                        agreements and policies.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">We will also retain your Personal Data for internal
                        analysis purposes. This data is generally retained for a shorter period of time, except when this
                        data is used to strengthen the security or to improve the functionality of The Doula App, or when we
                        are legally obligated to retain this data for longer time periods.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><strong><span
                            style="color: rgb(0, 0, 0);font-size: 12pt;">Security of Your Personal Data</span></strong></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">The security of your Personal Data is important to us,
                        but remember that no method of transmission over the Internet, or method of electronic storage is
                        100% secure. While we implement commercially acceptable means to protect your Personal Data, we
                        cannot guarantee its absolute security.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><strong><span
                            style="color: rgb(0, 0, 0);font-size: 12pt;">Delete Your Personal Data</span></strong></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">You have the right to delete or request that we assist
                        in deleting the Personal Data that we have collected about you. The Doula App may give you the
                        ability to delete certain information about you from within the app itself.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">You may update, amend, or delete your information at
                        any time by signing in to your account, if you have one, and visiting the account settings section
                        that allows you to manage your personal information.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">You may also contact us to request access to, correct,
                        or delete any personal information that you have provided to us. Please note, however, that we may
                        need to retain certain information when we have a legal obligation or lawful basis to do so.</span>
                </p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><strong><span
                            style="color: rgb(0, 0, 0);font-size: 12pt;">Children&apos;s Privacy</span></strong></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">The Doula App does not address anyone under the age of
                        13. We do not knowingly collect personally identifiable information from anyone under the age of 13.
                        If you are a parent or guardian and you are aware that your child has provided us with Personal
                        Data, please contact us. If We become aware that we have collected Personal Data from anyone under
                        the age of 13 without verification of parental consent, we take steps to remove that information
                        from our possession.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">If we need to rely on consent as a legal basis for
                        processing your information and your country requires consent from a parent, we may require your
                        parent&apos;s consent before we collect and use that information.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><strong><span
                            style="color: rgb(0, 0, 0);font-size: 12pt;">Links to Other Websites</span></strong></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">Our services may contain links to other websites that
                        are not operated by us. If you click on a third party link, you will be directed to that third
                        party&apos;s site. We strongly advise you to review the Privacy Policy of every site you
                        visit.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">We have no control over and assume no responsibility
                        for the content, privacy policies or practices of any third party sites or services.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><strong><span
                            style="color: rgb(0, 0, 0);font-size: 12pt;">Changes to this Privacy Policy</span></strong></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">We may update our Privacy Policy from time to time. We
                        will notify you of any changes by posting the new Privacy Policy on this page.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">We will let you know via a prominent notice on The
                        Doula App, prior to the change becoming effective and update the &quot;Last updated&quot; date at
                        the top of this Privacy Policy.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">You are advised to review this Privacy Policy
                        periodically for any changes. Changes to this Privacy Policy are effective when they are posted on
                        this page.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><br></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><strong><span
                            style="color: rgb(0, 0, 0);font-size: 12pt;">Contact Us</span></strong></p>
                <p style="text-align: left;color: rgb(0, 0, 0);font-size: medium;"><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">If you have any questions about this Privacy Policy,
                        you can contact us by email:</span></p>
                <ul style="list-style-type: none;text-align: start;color: rgb(0, 0, 0);font-size: medium;">
                    <li style="text-align: left;color: rgb(0, 0, 0);font-size: 12pt;"><a style="color: rgb(5, 99, 193)"
                            href="mailto:info@d-tt.nl">info@d-tt.nl</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy'
}
</script>