<template>
    <div>
        <Navigation class="jexa-new-navbar-area" />
        <MainBanner />
        <AboutUs />
        <Functionalities />
        <EvenMore />
        <AppScreens />        
        <Video />
        <Funfacts />        
        <CustomerReviews />
        <Download />
        <Footer />
    </div>
</template>

<script>
import Navigation from '../Layout/Navigation'
import MainBanner from '../Home/MainBanner'
import AboutUs from '../Home/AboutUs'
import Functionalities from '../Home/Functionalities'
import EvenMore from '../Home/EvenMore'
import AppScreens from '../Home/AppScreens'
import Video from '../Home/Video'
import Funfacts from '../Home/Funfacts'
import CustomerReviews from '../Home/CustomerReviews'
import Download from '../Home/Download'
import Footer from '../Layout/Footer'

export default {
    name: 'HomePageEight',
    components: {
        Navigation,
        MainBanner,
        AboutUs,
        Functionalities,
        EvenMore,
        AppScreens,
        Download,
        Video,
        Funfacts,
        CustomerReviews,
        Footer,
    }
}
</script>