<template>
    <div class="terms-conditions-area ptb-100">
        <div class="container">
            <div class="terms-conditions-content">
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 26pt;">
                    <b><span style="color: rgb(0, 0, 0);font-size: 26pt;">Terms of Service</span></b></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="font-size: 12pt;"><br></span><span style="color: rgb(0, 0, 0);font-size: 15pt;">1.
                            Introduction</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">Our aim is to keep this Agreement as readable as
                            possible, but in some cases for legal reasons, some of the language is required
                            &quot;legalese&quot;.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 15pt;">2. Your Acceptance of this Agreement</span>
                </p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="font-size: 12pt;">These terms of service are entered into by and between You
                            and&nbsp;</span><span style="font-size: 12pt;">Doula B.V.</span><span
                            style="font-size: 12pt;">&nbsp;(&quot;Company,&quot; &quot;we,&quot; &quot;our,&quot; or
                            &quot;us&quot;). The following terms and conditions, together with any documents they expressly
                            incorporate by reference (collectively &quot;Terms of Service&quot;), govern your access to and
                            use of the Doula app , including any content, functionality, and services offered on or through
                            the Doula app or https://doula.d-tt.dev&nbsp;</span><span
                            style="font-size: 12pt;">&nbsp;</span><span
                            style="color: rgb(0, 0, 0);font-size: 12pt;">(the &quot;App&quot;).</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">Please read the Terms of Service carefully
                            before you start to use the App.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="font-size: 12pt;">By using the App [or by clicking to accept or agree to the Terms of
                            Service when this option is made available to you], you accept and agree to be bound and abide
                            by these Terms of Service and our&nbsp;</span><u><span style="font-size: 12pt;">Privacy
                                Policy</span></u><span style="color: rgb(0, 0, 0);font-size: 12pt;">, incorporated herein
                            by reference. If you do not want to agree to these Terms of Service, you must not access or use
                            the App.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">You must be at least 13 years old to use this
                            App. However, children of all ages may use the App if enabled by a parent or legal guardian. If
                            you are under 18, you represent that you have your parent or guardian&apos;s permission to use
                            the App. Please have them read these Terms of Service with you.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">If you are a parent or legal guardian of a user
                            under the age of 18, by allowing your child to use the App, you are subject to the terms of
                            these Terms of Service and responsible for your child&apos;s activity on the App.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <strong><span style="color: rgb(0, 0, 0);font-size: 12pt;">BY ACCESSING AND USING THE APP,
                                YOU:</span></strong></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <strong><span style="color: rgb(0, 0, 0);font-size: 12pt;">ACCEPT AND AGREE TO BE BOUND AND COMPLY
                                WITH THESE TERMS OF SERVICE;<br>YOU REPRESENT AND WARRANT THAT YOU ARE THE LEGAL AGE OF
                                MAJORITY UNDER APPLICABLE LAW TO FORM A BINDING CONTRACT WITH US; AND,<br>YOU AGREE IF YOU
                                ACCESS THE APP FROM A JURISDICTION WHERE IT IS NOT PERMITTED, YOU DO SO AT YOUR OWN
                                RISK.</span></strong></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 15pt;">3. Updates to Terms of Service</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">We may revise and update these Terms of Service
                            from time to time in our sole discretion. All changes are effective immediately when we post
                            them and apply to all access to and use of the App thereafter.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">Continuing to use the App or making subsequent
                            purchases following the posting of revised Terms of Service means that you accept and agree to
                            the changes. You are expected to check this page each time you access the App so you are aware
                            of any changes, as they are binding on you.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 15pt;">4. Your Responsibilities</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">You are required to ensure that all persons who
                            access the App are aware of this Agreement and comply with it. It is a condition of your use of
                            the App that all the information you provide on the App is correct, current, and
                            complete.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR
                            USE OF THE APP AND YOUR COMPUTER, INTERNET AND DATA SECURITY.</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 15pt;">5. Prohibited Activities</span></p>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">You may use the App only for lawful purposes and
                            in accordance with these Terms of Service. You agree not to use the App:</span></p>
                <ul>
                    <li><span style="font-size: 12pt;">In any way that violates any applicable federal, state, local or
                                international law or regulation (including, without limitation, any laws regarding the
                                exports of&nbsp;</span><span style="font-size: 12pt;">data&nbsp;</span><span
                                style="font-size: 12pt;">to and from the European Economic Area</span><span
                                style="font-size: 12pt;">&nbsp;or other countries</span><span
                                style="color: rgb(0, 0, 0);font-size: 12pt;">).</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">For the purpose of exploiting, harming, or
                                attempting to exploit or harm minors in any way by exposing them to inappropriate content,
                                asking for personally identifiable information or otherwise.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">To transmit, or procure the sending of, any
                                advertising or promotional material, including any &quot;junk mail,&quot; &quot;chain
                                letter,&quot; &quot;spam,&quot; or any other similar solicitation.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">To impersonate or attempt to impersonate the
                                Company, a Company employee, another user, or any other persona or entity (including,
                                without limitation, by using email addresses associated with any of the
                                foregoing).</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">To engage in any other conduct that
                                restricts or inhibits anyone&apos;s use or enjoyment of the app, or which as determined by
                                us, may harm the Company or users of the app, or expose them to liability.</span></li>
                </ul>
                <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">Additionally, you agree not to:</span></p>
                <ul>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Use the App in any manner that could
                                disable, overburden, damage, or impair the app or interfere with any other party&apos;s use
                                of the App, including their ability to engage in real-time activities through the
                                App.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Use any robot, spider, or other automatic
                                device, process, or means to access the App for any purpose, including monitoring or copying
                                any of the material on the App.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Use any manual process to monitor or copy
                                any of the material on the App, or for any other purpose not expressly authorized in these
                                Terms of Service, without our prior written consent.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Use any device, software, or routine that
                                interferes with the proper working of the App.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Introduce any viruses, Trojan horses, worms,
                                logic bombs, or other material that is malicious or technologically harmful.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Attempt to gain unauthorized access to,
                                interfere with, damage, or disrupt any parts of the App, the server on which the App is
                                stored, or any server, computer, or database connected to the App.</span></li>
                    <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Attack the App via a denial-of-service
                                attack or a distributed denial-of-service attack.</span></li>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Otherwise attempting to interfere with the
                            proper working of the App.</span></li>
            </ul>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <br></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">6. Intellectual Property Rights</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="font-size: 12pt;">The App and its entire contents, features, and functionality
                        (including but not limited to all information, software, text, displays, images, video, and
                        audio, and the design, selection, and arrangement thereof) are owned by the Company, its
                        licensors, or other providers of such material and are protected by European
                        Union&nbsp;</span><span style="font-size: 12pt;">and international copyright</span><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">, trademark, patent, trade secret, and other
                        intellectual property or proprietary rights laws.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">These Terms of Service permit you to use the App
                        for your personal, non-commercial use only. You must not reproduce, distribute, modify, create
                        derivative works of, publicly display, publicly perform, republish, download, store, or transmit
                        any of the material on our App, except as follows:</span></p>
            <ul>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Your computer may temporarily store copies
                            of such material in RAM incidental to your accessing and viewing those materials.</span>
                </li>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">You may store files that are automatically
                            cached by your Web browser for display enhancement purposes.</span></li>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">You may print or download one copy of a
                            reasonable number of pages of the App for your own personal, non-commercial use and not for
                            further reproduction, publication or distribution.</span></li>
            </ul>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">You must not:</span></p>
            <ul>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Modify copies of any materials from this
                            app.</span></li>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Delete or alter any of the copyright,
                            trademark, or other proprietary rights notices from copies of materials from this
                            app.</span></li>
            </ul>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">You must not access or use for any commercial
                        purposes any part of the app or any services or materials available through the App.</span>
            </p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">If you print, copy, modify, download, or
                        otherwise use or provide any other person with access to any part of the App in breach of the
                        Terms of Service, your right to use the App will stop immediately and you must, at our option,
                        return or destroy any copies of the materials you have made. No right, title, or interest in or
                        to the App or any content on the App is transferred to you, and all rights not expressly granted
                        are reserved by the Company. Any use of the App not expressly permitted by these Terms of
                        Service is a breach of these Terms of Service and may violate copyright, trademark, and other
                        laws.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">7. Our Rights</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">We have the right, without provision of notice
                        to:</span></p>
            <ul>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Take appropriate legal action, including,
                            without limitation, referral to or cooperation with law enforcement or regulatory
                            authorities, or notifying the harmed party of any illegal or unauthorized use of the App;
                            and</span></li>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Terminate or suspend your access to all or
                            part of the App for any or no reason, including, without limitation, any violation of these
                            Terms of Service.</span></li>
            </ul>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">YOU WAIVE AND HOLD HARMLESS DOULA B.V. AND ITS
                        PARENT, SUBSIDIARIES, AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS,
                        SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, AND SUCCESSORS FROM ANY AND ALL
                        CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE COMPANY AND ANY OF THE FOREGOING PARTIES RELATING
                        TO ANY, INVESTIGATIONS BY EITHER THE COMPANY OR BY LAW ENFORCEMENT AUTHORITIES.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">8. Third-Party Links and Content</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">For your convenience, this App may provide links
                        or pointers to third-party sites or third-party content. We make no representations about any
                        other app, website or third-party content that may be accessed from this App. If you choose to
                        access any such content, you do so at your own risk. We have no control over the third-party
                        content or any such third-party sites and accept no responsibility for such sites or for any
                        loss or damage that may arise from your use of them. You are subject to any terms and conditions
                        of such third-party sites.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <br></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">9. Mobile Application</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">By using our mobile application, you acknowledge
                        and agree to the following:</span></p>
            <ul style="list-style-type: none;text-align: start;color: rgb(0, 0, 0);font-size: medium;">
                <li style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">1. If the mobile application fails to
                            conform to any applicable warranty, you may notify the applicable application distributor,
                            and the application distributor may refund any applicable purchase price in accordance with
                            its terms and policies, and to the maximum extent permitted by applicable law, the
                            application distributor will have no other warranty obligation with respect to the mobile
                            application;</span></li>
                <li style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">2. You must comply with applicable
                            third-party terms of service when using the mobile application; and</span></li>
                <li style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                    <span style="color: rgb(0, 0, 0);font-size: 12pt;">3. You acknowledge and agree that the
                            application distributors are third-party beneficiaries of the terms and conditions in this
                            mobile application license contained in these Terms of Service, and that each application
                            distributor will have the right to enforce the terms and conditions in this mobile
                            application license contained in these Terms of Use against you as a third-party beneficiary
                            thereof.</span></li>
            </ul>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">10. Online Orders</span></p>
            <p><span style="color: rgb(0, 0, 0);font-size: 12pt;">All orders, purchases or transactions for the
                        sales of goods, digital products, or information made using this App are subject to the
                        following additional terms and conditions of sale:</span></p>
            <ul>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">You may not order or obtain goods, digital
                            products or information from this App if you: (i) are prohibited from accessing or using
                            this App or any of this App&apos;s contents, goods, digital products or information by
                            applicable law or (ii) you are outside the geographic region where the goods, digital
                            products or information are available for purchase;</span></li>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">All prices, discounts, and promotions posted
                            on this App are subject to change without notice. The price and currency charged for goods,
                            digital products or information will be the price and currency advertised on this App at the
                            time the order is placed, subject to the terms of any promotions or discounts that may be
                            applicable. The price and currency charged will be clearly stated in the shopping cart
                            before you confirm your purchase. Price increases will only apply to orders placed after the
                            time of the increase.</span></li>
            </ul>
            <p><span style="color: rgb(0, 0, 0);font-size: 12pt;">Where an order indicates a license is being
                        purchased:</span></p>
            <ul>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">All uses on this App of the terms
                            &quot;sell&quot;, &quot;sale&quot;, &quot;resell&quot;, &quot;resale&quot;,
                            &quot;purchase&quot;, &quot;price&quot;, and the like mean the purchase or sale of a
                            license;</span></li>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">You will comply with all terms and
                            conditions of the applicable license Terms of Service for any goods, digital products or
                            information you obtain through this App, and you will not cause, induce, or permit
                            others&apos; non-compliance with the terms and conditions of any of license Terms of
                            Services for the goods, digital products or information; and</span></li>
                <li><span style="color: rgb(0, 0, 0);font-size: 12pt;">Except for the limited license granted under
                            the relevant license Terms of Service, nothing in these Terms of Service grants any right,
                            title, or interest in or to (including any license under) any intellectual property rights
                            in or relating to, the good, digital product or information, whether expressly, by
                            implication, estoppel, or otherwise. All right, title, and interest in and to the good,
                            digital product or information are and will remain with Doula B.V. or its licensors, as
                            applicable.</span></li>
            </ul>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <br></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">11. Disclaimers, Liability and
                        Indemnification</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE
                        APP, ITS CONTENT, AND ANY GOODS, DIGITAL PRODUCTS, SERVICES, INFORMATION OR ITEMS FOUND OR
                        ATTAINED THROUGH THE APP IS AT YOUR OWN RISK. THE APP, ITS CONTENT, AND ANY GOODS, SERVICES,
                        DIGITAL PRODUCTS, INFORMATION OR ITEMS FOUND OR ATTAINED THROUGH THE APP ARE PROVIDED ON AN
                        &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF
                        ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT
                        AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">YOU ACKNOWLEDGE AND AGREE THAT DOULA B.V.
                        &nbsp;OR ITS RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS,
                        LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE NO WARRANTY, REPRESENTATION, OR ENDORSEMENT
                        WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR
                        AVAILABILITY OF THE APP OR ITS CONTENTS OR THAT ANY GOODS, SERVICES, DIGITAL PRODUCTS,
                        INFORMATION OR ITEMS FOUND OR ATTAINED THROUGH THE APP WILL BE ACCURATE, RELIABLE, ERROR-FREE,
                        OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR APP OR THE SERVER THAT MAKES IT
                        AVAILABLE OR CONTENT ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR DESTRUCTIVE
                        CODE.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <strong><span style="color: rgb(0, 0, 0);font-size: 12pt;">How We Limit Our Liability to
                            You</span></strong></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY
                        LAW, IN NO EVENT SHALL THE COMPANY NOR ITS RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS,
                        SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE UNDER
                        THESE TERMS OF SERVICE TO YOU OR ANY THIRD-PARTY FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL,
                        EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES WHATSOEVER, INCLUDING ANY DAMAGES FOR BUSINESS
                        INTERRUPTION, LOSS OF USE, DATA, REVENUE OR PROFIT, COST OF CAPITAL, LOSS OF BUSINESS
                        OPPORTUNITY, LOSS OF GOODWILL, WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING
                        NEGLIGENCE), ANY OTHER THEORY OF LIABILITY, OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES
                        WERE FORESEEABLE AND WHETHER OR NOT THE COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH
                        DAMAGES.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <strong><span style="color: rgb(0, 0, 0);font-size: 12pt;">Indemnification</span></strong></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">To the maximum extent permitted by applicable
                        law, you agree to defend, indemnify, and hold harmless Doula B.V., its parent, subsidiaries,
                        affiliates, and their respective directors, officers, employees, agents, service providers,
                        contractors, licensors, suppliers, successors, and assigns from and against any claims,
                        liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable
                        attorneys&apos; fees) arising out of or relating to your breach of these Terms of Service or
                        your use of the App including, but not limited to, third-party sites and content, any use of the
                        App&rsquo;s content and services other than as expressly authorized in these Terms of Service or
                        any use of any goods, digital products and information purchased from this App.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">12. Privacy Policy</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="font-size: 12pt;">Your provision of personal information through the App is governed
                        by our&nbsp;</span><u><span style="color: rgb(17, 85, 204);font-size: 12pt;"><a
                                href="/privacy-policy"
                                style="color: inherit;">Privacy Policy</a></span></u><u><span
                            style="color: rgb(17, 85, 204);font-size: 12pt;"><a
                                href="/privacy-policy"
                                style="color: inherit;">&nbsp;</a></span></u><span style="font-size: 12pt;">(the
                        &quot;</span><strong><span style="font-size: 12pt;">Privacy Policy</span></strong><span
                        style="color: rgb(0, 0, 0);font-size: 12pt;">&quot;).</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">13. Governing Law</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">The App and these Terms of Service will be
                        governed by and construed in accordance with the laws of and any applicable federal laws
                        applicable therein, without giving effect to any choice or conflict of law provision, principle,
                        or rule and notwithstanding your domicile, residence, or physical location. Any action or
                        proceeding arising out of or relating to this App and/or under these Terms of Service will be
                        instituted in the courts of the Country of Netherlands, and each party irrevocably submits to
                        the exclusive jurisdiction of such courts in any such action or proceeding. You waive any and
                        all objections to the exercise of jurisdiction over you by such courts and to the venue of such
                        courts.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">The parties agree that the United Nations
                        Convention on Contracts for the International Sale of Goods will not govern these Terms of
                        Service or the rights and obligations of the parties under these Terms of Service.</span>
            </p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">14. Severability</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">If any provision of these Terms of Service is
                        illegal or unenforceable under applicable law, the remainder of the provision will be amended to
                        achieve as closely as possible the effect of the original term and all other provisions of these
                        Terms of Service will continue in full force and effect.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">15. Entire Terms of Service</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">These Terms of Service constitute the entire and
                        only Terms of Service between the parties in relation to its subject matter and replaces and
                        extinguishes all prior or simultaneous Terms of Services, undertakings, arrangements,
                        understandings or statements of any nature made by the parties or any of them whether oral or
                        written (and, if written, whether or not in draft form) with respect to such subject matter.
                        Each of the parties acknowledges that they are not relying on any statements, warranties or
                        representations given or made by any of them in relation to the subject matter of these Terms of
                        Service, save those expressly set out in these Terms of Service, and that they shall have no
                        rights or remedies with respect to such subject matter otherwise than under these Terms of
                        Service save to the extent that they arise out of the fraud or fraudulent misrepresentation of
                        another party. No variation of these Terms of Service shall be effective unless it is in writing
                        and signed by or on behalf of Doula B.V..</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">16. Waiver</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">No failure to exercise, and no delay in
                        exercising, on the part of either party, any right or any power hereunder shall operate as a
                        waiver thereof, nor shall any single or partial exercise of any right or power hereunder
                        preclude further exercise of that or any other right hereunder.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">17. Notice</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">We may provide any notice to you under these
                        Terms of Service by: (i) sending a message to the email address you provide to us and consent to
                        us using; or (ii) by posting to the App. Notices sent by email will be effective when we send
                        the email and notices we provide by posting will be effective upon posting. It is your
                        responsibility to keep your email address current.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">To give us notice under these Terms of Service,
                        you must contact us as follows: (i) by personal delivery, overnight courier or registered or
                        certified mail to Doula B.V., Sint Nicolaasstraat 9, 1012 NJ, Amsterdam, NL. We may update the
                        address for notices to us by posting a notice on this App. Notices provided by personal delivery
                        will be effective immediately once personally received by an authorized representative of Doula
                        B.V.. Notices provided by overnight courier or registered or certified mail will be effective
                        once received and where confirmation has been provided to evidence the receipt of the
                        notice.</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 15pt;">18. Contact Us</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="color: rgb(0, 0, 0);font-size: 12pt;">This App is operated by Doula B.V., Sint
                        Nicolaasstraat 9, 1012 NJ, Amsterdam, NL</span></p>
            <p style="text-align: left;color: rgb(0, 0, 0);background-color: rgb(255, 255, 255);font-size: 12pt;">
                <span style="font-size: 12pt;">Should you become aware of misuse of the app including libelous or
                        defamatory conduct, you must report it to the Company. All reports of misuse and other feedback,
                        comments, requests for technical support, and other communications relating to the App should be
                        directed to info@d-tt.nl.</span></p>
            <p></p>
        </div>
    </div>
</div></template>

<script>
export default {
    name: 'TermsConditions'
}
</script>