<template>
    <div v-if="footer" class="footer-wrap-area footer-gradient-color pt-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <router-link to="/" class="logo">
                            <img :src="fetchImage(footer.logo.data.attributes.url)" alt="logo">
                        </router-link>
                        <p>{{ footer.description }}</p>
                    </div>
                </div>    
                <!-- Dynamic links -->            
                <div v-for="linkBox in footer.footerLinks" :key="linkBox.id" class="col-lg-2 col-md-3 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>{{ linkBox.title }}</h3>
                        <ul class="links-list">
                            <li v-for="link in linkBox.footerLink" :key="link.id">
                                <a :href="link.url">{{ link.title }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- Download buttons -->
                <div class="col-lg-2 col-md-3 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Download</h3>
                        <ul class="links-list">
                            <li>
                                <a :href="LinkConstants.links.apple" class="playstore-button" target="_blank">
                                    <img src="@/assets/images/apple_store_button_black.webp" alt="applestore-button">                           
                                </a>
                            </li>
                            <li>
                                <a :href="LinkConstants.links.google" class="playstore-button" target="_blank">
                                    <img src="@/assets/images/google_play_button_black.webp" alt="playstore-button">                           
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="copyright-area">
                <a href="https://www.d-tt.nl/" target="_blank" class="copyright-text">Developer DTT | apps - web - games</a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import LinkConstants from '@/constants/links'

export default {
    name: 'FooterStyleFour',
    data() {
        return {
            footer: null,
            LinkConstants,
            currentYear: new Date().getFullYear(),
        };
    },
    methods: {
        fetchImage(imgUrl) {
            return `${this.$baseUrl}${imgUrl}`
        },
    },
    created: async function (){
        const response = await axios.get(`${this.$baseUrl}/api/footer?populate=deep`)
        const { data: {attributes} } = response.data
        this.footer = attributes
    },
}
</script>

<style scoped lang="scss">
.footer-gradient-color {
    background-image: linear-gradient(to bottom, #5389D9, #BAA5BC);
}

.copyright-text {
    font-weight: 700;
    color: var(--whiteColor);

    &:hover {
        color: var(--mainColor);
    }
}

.row {
    justify-content: space-between;
}

.logo > img {
    height: 3rem;
}

.links-list li a {
    font-size: 14px;
}

.links-list img {
    height: 2.3rem;
}

@media (min-width: 768px) {
    .links-list li a {
        font-size: 16px;
    }
}
</style>