<template>
    <div v-if="data" class="key-features-area bg-transparent-with-color pt-100 pb-50">
        <div class="container">
            <div class="section-title">
                <h2 class="sub-title">{{ data.subtitle }}</h2>
                <h2>{{ data.heading }}</h2>
            </div>
            <div class="even-more-content-container">
                <div v-for="item in data.evenMoreItem" :key="item.id">
                    <div class="key-features-card bg-color-two style-two">
                        <div class="icon bg2">
                            <img :src="fetchImage(item.icon.data.attributes.url)" alt="even-more-icon">
                        </div>
                        <h3>{{ item.title }}</h3>
                        <p>{{ item.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'BestFeaturesEver',
    data (){
      return {
          data: null,
      }
  },
  methods: {
      fetchImage(imgUrl) {
          return `${this.$baseUrl}${imgUrl}`
      },
  },
  created: async function (){
      const response = await axios.get(`${this.$baseUrl}/api/even-more?populate=deep`)
      const { data: {attributes} } = response.data
      this.data = attributes;
    },
}
</script>

<style scoped>
.key-features-card .icon img {
    display: inline-block;
    height: 45px;
    width: 45px;
    line-height: 75px;
    color: var(--whiteColor);
    border-radius: 100px 100px 0 100px;
    text-align: center;
    font-size: 30px;
    transition: var(--transition);
}

.even-more-content-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1.5rem;
}

.key-features-card.style-two {
    height: 100%;
}

@media (min-width: 768px) {
    .even-more-content-container {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 1400px) {
    .even-more-content-container {
        grid-template-columns: repeat(3, 1fr);
    }
}
</style>