import { createWebHistory, createRouter } from "vue-router";

// All Pages
import HomePage from "../components/Pages/HomePage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import TermsConditionsPage from "../components/Pages/TermsConditionsPage";

const routes = [
  { path: "/", component: HomePage },
  { path: "/privacy-policy", component: PrivacyPolicyPage },
  { path: "/terms-conditions", component: TermsConditionsPage },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash, top: 70,
        behavior: 'smooth',
      }
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    return next('/');
  }
  next();
});

export default router;
