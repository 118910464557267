<template>
    <div v-if="functionalities" class="app-about-area pb-100" id="functionalities">
        <div v-for="(functionality, index) in functionalities.functionality" :key="index">

            <div class="container container-section-one pb-100">
                <div class="row align-items-center" :class="{ 'row-reverse-desk':index % 2 !== 0 }">
                    <div class="col-lg-6 col-md-12">
                        <div class="app-about-image">
                            <img :src="fetchImage(functionality.image.data.attributes.url)" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="app-about-content">
                            <h2 class="sub-title">{{ functionality.subtitle }}</h2>
                            <h2>{{ functionality.heading }}</h2>
                            <p>{{ functionality.description }}</p>
                            <ul class="list">
                                <li v-for="item in functionality.functionalityItem" :key="item.id">
                                    <div class="icon bg-3">
                                        <img v-if="item.icon" :src="fetchImage(item.icon.data.attributes.url)">
                                    </div>
                                    <h3>{{ item.title}}</h3>
                                    <p>{{ item.description }}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>      
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'About',
    data (){
      return {
          functionalities: null,
      }
  },
  methods: {
      fetchImage(imgUrl) {
          return `${this.$baseUrl}${imgUrl}`
      },
  },
  created: async function (){
      const response = await axios.get(`${this.$baseUrl}/api/functionality?populate=deep,10`)
      const { data: {attributes} } = response.data
      this.functionalities = attributes;
  },
}
</script>

<style scoped>
.app-about-area {
    padding-top: 2rem;
}

@media only screen and (min-width: 992px) {
    .row-reverse-desk {
        flex-direction: row-reverse;
    }
}

.icon > img {
    border-radius: 50%;
}
</style>
