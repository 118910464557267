<template>
    <div v-if="data" class="app-screenshots-area ptb-100 app-screens-wrapper" id="examples">
        <div class="section-title">
            <h2 class="sub-title">{{ data.subtitle }}</h2>
            <h2>{{ data.heading }}</h2>
        </div>
        <div class="app-screenshots-slides">
            <carousel
                :autoplay="5000"
                :settings='settings'
                :breakpoints='breakpoints'
            >
                <slide 
                    v-for="slide in data.singleScreenshot" 
                    class="carousel-slide-item"
                    :key="slide.id"
                >
                    <div class="single-screenshot-item">
                        <img :src="fetchImage(slide.image.data.attributes.url)" alt="app-screenshot">
                    </div>
                </slide>
                
                <template #addons>
                    <Pagination />
                </template>
            </carousel>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'AppScreens',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    data: () => ({
        data: null,
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
            screenshots: null,
            wrapAround: true,
        },
        breakpoints: {
            0: {
                itemsToShow: 1.8,
                snapAlign: 'center',
			},
            576: {
                itemsToShow: 2.2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 3.8,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 3.8,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 5.2,
                snapAlign: 'center',
            },
        },
    }),
    methods: {
        fetchImage(imgUrl) {
            return `${this.$baseUrl}${imgUrl}`
        },
    },
    created: async function (){
        const response = await axios.get(`${this.$baseUrl}/api/appscreen?populate=deep`)
        const { data: {attributes} } = response.data
        this.data = attributes;
    },
})
</script>

<style scoped>
.app-screens-wrapper {
    max-width: 100vw;
    overflow: hidden;
}

.carousel-slide-item {
    max-width: 300px;
}
</style>