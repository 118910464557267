<template>
    <div v-if="funfacts" class="gradient-funfacts-area pt-100 pb-75">
        <img class="stars" src="@/assets/images/stars.png" alt="stars">
        <div class="container">           
            <div class="row justify-content-center">
                <div v-for="funfact in funfacts.funfactsBox" :key="funfact.id" class="col-lg-3 col-sm-6 col-md-6">
                    <div class="single-funfacts-card">
                        <div v-if="funfact.image" class="single-funfacts-image-container">
                            <img class="fun-fact-image" :src="fetchImage(funfact.image.data.attributes.url)" alt="image">
                        </div>
                        <div v-else class="icon">
                            <i :class="funfact.icon"></i>
                        </div>
                        <p>{{ funfact.title }}</p>
                        <h3>{{ funfact.number }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Funfacts',
    data: () => ({
        funfacts: null,
    }),
    methods: {
        fetchImage(imgUrl) {
            return `${this.$baseUrl}${imgUrl}`
        },
    },
    created: async function (){
        const response = await axios.get(`${this.$baseUrl}/api/funfact?populate=deep`)
        const { data: {attributes} } = response.data
        this.funfacts = attributes;
    },
}
</script>

<style scoped>
.container {
    position: relative;
}

.fun-fact-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.stars {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    top: 3rem;
    width: 90%;
    margin: 0 auto;
    display: none;
}

.gradient-funfacts-area {
    position: relative;
}
.single-funfacts-image-container {
    display: inline-block;
    height: 70px;
    width: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 50px;
    background-color: var(--whiteColor);
}

.fun-fact-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    padding: 1rem;
}

@media only screen and (min-width: 1000px) {
    .stars {
        display: block;
    }
}
</style>