<template>
    <div>
        <Navigation class="jexa-new-navbar-area" />
        <PageTitle class="bg-color" pageTitle="Privacy Policy" />
        <PrivacyPolicy />
        <Download />
        <Footer />
    </div>
</template>

<script>
import Navigation from '../Layout/Navigation'
import PageTitle from '../Common/PageTitle'
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import Download from '../Home/Download'
import Footer from '../Layout/Footer'

export default {
    name: 'PrivacyPolicyPage',
    components: {
        Navigation,
        PageTitle,
        PrivacyPolicy,
        Download,
        Footer,
    }
}
</script>