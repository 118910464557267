<template>
    <div v-if="data" class="feedback-wrap-area ptb-100" id="reviews">
        <div class="container">
            <div class="section-title">
                <h2 class="sub-title">{{ data.subtitle }}</h2>
                <h2>{{ data.heading }}</h2>
            </div>
            <div class="feedback-swiper-wrap-slides">
                <carousel
                    :autoplay="5000"
                    :settings='settings'
                >
                    <slide 
                        v-for="slide in data.singleFeedbacks" 
                        :key="slide.id"
                    >
                        <div class="single-feedback-wrap-item">
                            <div class="rating">
                                <h5>{{ slide.title }}</h5>
                                <div>
                                    <i v-for="(star, index) in slide.rating" :key="index" class="ri-star-fill"></i>
                                </div>
                            </div>
                            <p>{{ slide.desc }}</p>
                            <div class="client-info">
                                <img v-if="slide.image.data?.attributes?.url" :src="fetchImage(slide.image.data.attributes.url)" alt="user">
                                <div class="title">
                                    <h3>by {{slide.name}}</h3>
                                </div>
                            </div>
                        </div>
                    </slide>

                    <template #addons>
                        <navigation>
                            <template #next>
                                <img src="../../assets/images/btn_arrow_right.svg" alt="Next icon">
                            </template>
                            <template #prev>
                                <img src="../../assets/images/btn_arrow_left.svg" alt="Previous icon">
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'CustomerReviews',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        data: null,
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
            wrapAround: true,
        },
    }),
    methods: {
        fetchImage(imgUrl) {
            return `${this.$baseUrl}${imgUrl}`
        },
    },
    created: async function (){
        const response = await axios.get(`${this.$baseUrl}/api/customerreview?populate=deep`)
        const { data: {attributes} } = response.data
        this.data = attributes
    },
})
</script>

<style>
.single-feedback-wrap-item p {
    height: 8rem;
    line-height: 2;
}

/** Remove default styling for carousel buttons */
.carousel__next,
.carousel__next::before,
.carousel__prev,
.carousel__prev::before {
    background: none !important;
}

@media only screen and (max-width: 991px) {
    .single-feedback-wrap-item p {
        height: 7rem;
        line-height: 1.8;
    }
}
@media only screen and (max-width: 767px) {
    .single-feedback-wrap-item p {
        height: 8rem;
    }
}
@media only screen and (max-width: 500px) {
    .single-feedback-wrap-item p {
        height: 11rem;
    }
}
@media only screen and (max-width: 400px) {
    .single-feedback-wrap-item p {
        height: 12rem;
    }
}
.single-feedback-wrap-item p::-webkit-scrollbar {
  background-color: transparent;
  width: 5px;
}

.single-feedback-wrap-item p::-webkit-scrollbar-thumb {
    background-color: #609CF4;
    border-radius: 10px;
}

@media only screen and (max-width: 1070px) {
    .feedback-swiper-wrap-slides button {
        display: none;
    }
}
</style>