<template>
    <div class="new-app-main-banner-wrap-area">
        <div class="container-fluid">
            <div v-if="banner !== null" class="row align-items-center banner-container">
                <div class="col-lg-6 col-md-12">
                    <div class="new-app-main-banner-wrap-content">
                        <h2 class="sub-title">{{ banner.subtitle }}</h2>
                        <h1>{{ banner.heading }}</h1>
                        <p>{{ banner.description }}</p>

                        <ul class="user-info">
                            <li v-for="user in banner.userImages.data" :key="user.id">
                                <img :src="fetchImage(user.attributes.url)" class="user-image" alt="user-image">
                            </li>
                            <li class="title">{{ banner.userAmount }}</li>
                        </ul>

                        <div class="app-button-box">
                            <a :href="LinkConstants.links.apple" class="applestore-button" target="_blank">
                                <img class="applestore-image-button" src="../../assets/images/apple_store_button.webp" alt="apple-store-button">
                            </a>

                            <a :href="LinkConstants.links.google" class="playstore-button" target="_blank">
                                <img class="playstore-image-button" src="../../assets/images/google_play_button.webp" alt="google-play-button">                           
                            </a>
                        </div>
                    </div>
                </div> 
                <div class="col-lg-6 col-md-12">
                    <div class="new-app-main-banner-wrap-image" data-aos="fade-left" data-aos-duration="2000">
                        <img class="banner-image-mock-up" :src="fetchImage(banner.image.data.attributes.url)" alt="image">
                        <!-- TODO -->
                        <!-- The location of the shapes are based on an ever expanding container, which is not good for responsiveness -->
                        <!-- Make the container have a fixed width to avoid this -->
                        <div class="wrap-image-shape-1">
                            <img src="@/assets/images/more-home/banner/cloud-2.png" alt="cloud-image">
                        </div>
                        <div class="wrap-image-shape-2">
                            <img src="@/assets/images/more-home/banner/cloud-1.png" alt="cloud-image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import LinkConstants from '@/constants/links'

export default {
    name: 'MainBanner',
    data (){
        return {
            banner: null,
            LinkConstants,
        }
    },
    methods: {
        fetchImage(imgUrl) {
            return `${this.$baseUrl}${imgUrl}`
        },
    },
    created: async function (){
        const response = await axios.get(`${this.$baseUrl}/api/banner-home?populate=deep`)
        const { data: {attributes} } = response.data
        this.banner = attributes;
    },
}
</script>

<style scoped>
.banner-image-mock-up {
    position: relative;
    z-index: 10;
    max-height: 80vw;
}

.wrap-image-shape-2 {
    z-index: 11;
}

.app-button-box {
    margin-top: 2rem;
}

.app-button-box img {
    height: 3.5rem;
}

.applestore-button {
    margin-right: 0.8rem;
}

.applestore-image-button, .playstore-image-button {
    margin-bottom: 0.4rem;
}

.user-image {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    object-fit: cover;
}

@media only screen and (min-width: 700px) {
    .banner-image-mock-up {
        max-height: 30rem ;
    }
}

@media only screen and (min-width: 1200px) {
    .banner-image-mock-up {
        max-height: 40rem;
    }
}
</style>