<template>
    <div v-if="download" class="app-download-area pb-200">
        <div class="app-download-inner bg-gray">
            <div class="container">     
                <div class="intro-video-shape">
                <img class="cloud-image" src="@/assets/images/cloud-small.svg" alt="cloud-image">   
                </div>
                <div 
                    class="row align-items-center app-download-content-container"
                    v-if="download !== null"
                >
                    
                    <div class="col-lg-6 col-md-12">
                        <div class="app-download-content">
                            <h2 class="sub-title">{{download.subtitle}}</h2>
                            <h2>{{download.heading}}</h2>
                            <p>{{download.description}}</p>
                            
                            <div class="app-button-box">
                                <a :href="LinkConstants.links.apple" class="applestore-button" target="_blank">
                                    <img src="../../assets/images/apple_store_button.webp" alt="apple-store-button">
                                </a>

                                <a :href="LinkConstants.links.google" class="playstore-button" target="_blank">
                                    <img src="../../assets/images/google_play_button.webp" alt="google-play-button">                           
                                </a>
                            </div>
                        </div>
                    </div>
                
                    <div class="download-image-container" data-aos="fade-up">
                        <img class="download-image" :src="fetchImage(download.image.data.attributes.url)" alt="app-image">
                    </div>         
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import LinkConstants from '@/constants/links'

export default {
    name: 'AppDownload',
    data (){
        return {
            download: null,
            LinkConstants,
        }
    },
    methods: {
        fetchImage(imgUrl) {
            return `${this.$baseUrl}${imgUrl}`
        },
    },
    created: async function (){
        const response = await axios.get(`${this.$baseUrl}/api/download?populate=deep`)
        const { data: {attributes} } = response.data
        this.download = attributes
    },
}
</script>

<style scoped>
.app-download-content-container {
    position: relative;
    display: flex;
    flex-direction: row;
}

.download-image-container {
    position: relative;
    height: 100%;
    width: auto;

    margin: 2rem auto;
}

.download-image {
    width: auto;
    object-fit: contain;
}

.app-button-box {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
}

.app-button-box img {
    height: 2.3rem;
    width: auto;
}

.applestore-button {
    margin-right: 0.8rem;
}

.cloud-image {
    position: absolute;
    top: -3rem;
    left: 2.8rem;
    width: 8rem;
    animation: movebounce 5s linear infinite;
}

@media only screen and (min-width: 500px) {
    .cloud-image {
        left: 4rem;
    }

    .app-button-box img {
        height: 2.8rem;
        width: auto;
    }
}

@media only screen and (min-width: 1000px) {
    .app-button-box {
        justify-content: flex-start;
    }

    .app-download-content-container {
       height: 30rem;
    }
    .app-download-content-container > div {
        max-width: 50%;
    }

    .download-image-container {
        position: relative;
        height: 100%;
        width: auto;
        margin: 0 auto;
    }

    .download-image {
        height: 100%;
        width: auto;
    }
}

@media only screen and (min-width: 1400px) {
    .cloud-image {
        left: 7rem;
    }

    .app-download-content-container {
        height: 30rem;
    }
    .download-image-container {
        position: absolute;
        top: -5%;
        right: 0;
        height: 115%;
        width: auto;
    }

    .download-image {
        height: 100%;
        width: auto;
    }
}
</style>