<template>
    <div>
        <Navigation class="jexa-new-navbar-area" />
        <PageTitle class="bg-color" pageTitle="Terms & Conditions" />
        <TermsConditions />
        <Download/>
        <Footer />
    </div>
</template>

<script>
import Navigation from '../Layout/Navigation'
import PageTitle from '../Common/PageTitle'
import TermsConditions from '../TermsConditions/TermsConditions'
import Download from '../Home/Download'
import Footer from '../Layout/Footer'

export default {
    name: 'TermsConditionsPage',
    components: {
        Navigation,
        PageTitle,
        TermsConditions,
        Download,
        Footer,
    }
}
</script>