<template>
  <div class="features-area pt-100 pb-75" id="aboutUs">
      <div class="container">
          <div v-if="about" class="row align-items-center">
              <div class="col-lg-6 col-md-12">
                  <div class="features-inner-content">
                      <h2 class="sub-title">{{ about.subtitle}}</h2>
                      <h2>{{ about.heading }}</h2>
                      <p>{{ about.description }}</p>
                  </div>
              </div>
              <div class="col-lg-6 col-md-12 features-inner-list">
                  <div class="about-us-card-container">
                      <div class="about-us-card" v-if="about.aboutItem[0]">
                          <div class="features-inner-card">
                              <div class="icon">
                                  <img :src="fetchImage(about.aboutItem[0].image.data.attributes.url)" alt="about-us-icon">
                                  <h3>{{ about.aboutItem[0].title }}</h3>
                              </div>
                              <p>{{ about.aboutItem[0].description }}</p>
                          </div>
                      </div>

                      <div class="about-us-card"  v-if="about.aboutItem[1]">
                          <div class="features-inner-card with-box-shadow">
                              <div class="icon">
                                  <img :src="fetchImage(about.aboutItem[1].image.data.attributes.url)" alt="about-us-icon">                                
                                  <h3>{{ about.aboutItem[1].title }}</h3>
                              </div>
                              <p>{{ about.aboutItem[1].description }}</p>
                          </div>
                      </div>

                      <div class="about-us-card"  v-if="about.aboutItem[2]">
                          <div class="features-inner-card with-box-shadow">
                              <div class="icon">
                                  <img :src="fetchImage(about.aboutItem[2].image.data.attributes.url)" alt="about-us-icon">                                  
                                  <h3>{{ about.aboutItem[2].title }}</h3>
                              </div>
                              <p>{{ about.aboutItem[2].description }}</p>
                          </div>
                      </div>

                      <div class="about-us-card"  v-if="about.aboutItem[3]">
                          <div class="features-inner-card">
                              <div class="icon">
                                  <img :src="fetchImage(about.aboutItem[3].image.data.attributes.url)" alt="about-us-icon">                                  
                                  <h3>{{ about.aboutItem[3].title }}</h3>
                              </div>
                              <p>{{ about.aboutItem[3].description }}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AboutUs',
  data (){
      return {
          about: null,
      }
  },
  methods: {
      fetchImage(imgUrl) {
          return `${this.$baseUrl}${imgUrl}`
      },
  },
  created: async function (){
      const response = await axios.get(`${this.$baseUrl}/api/about?populate=deep`)
      const { data: {attributes} } = response.data
      this.about = attributes
  },
}
</script>

<style scoped>
.features-inner-card .icon img {
    display: inline-block;
    width: 50px;
    height: 50px;
    object-fit: cover;
    line-height: 55px;
    font-size: 25px;
    text-align: center;
    margin-bottom: 20px;
    transition: var(--transition);
    background: #A1C7FF;
    color: var(--whiteColor);
    border-radius: 100px 100px 0px 100px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.about-us-card-container {
    display: flex;
    flex-direction: column;
}

.features-inner-card {
    height: 100%;
}

.about-us-card:nth-child(4) { order: 3; }
.about-us-card:nth-child(3) { order: 4; }

@media (min-width: 500px) {
    .about-us-card-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    .about-us-card:nth-child(2),
    .about-us-card:nth-child(4) {
        transform: translateY(25px);
    }
    
    .about-us-card:nth-child(3) { order: 3; }
    .about-us-card:nth-child(4) { order: 4; }
}

@media (min-width:1000px) and (max-width:1400px) {
    .icon > h3 {
        margin-left: 0.9rem;
    }
}
</style>