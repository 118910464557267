<template>
  <div :class="['navbar-area', { 'is-sticky': isSticky, 'nav-border-bottom': !isSticky }]">
    <div class="jexa-nav">
      <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
          <router-link class="navbar-brand" to="/" v-if="logo !== null">
            <img :src="fetchImage(logo.logo.data.attributes.url)" alt="logo" />
            <img :src="fetchImage(logo.blackLogo.data.attributes.url)" alt="logo" />
          </router-link>

          <div
            class="navbar-toggler"
            @click="active = !active"
            :aria-pressed="active ? 'true' : 'false'"
            v-bind:class="{ active: button_active_state }"
            v-on:click="button_active_state = !button_active_state"
          >
            <span class="icon-bar top-bar"></span>
            <span class="icon-bar middle-bar"></span>
            <span class="icon-bar bottom-bar"></span>
          </div>

          <div class="collapse navbar-collapse" :class="{ show: active }">
            <ul class="navbar-nav">
              <li class="nav-item">
                <router-link to="/#aboutUs" @click="scrollToSection">
                  <a class="nav-link">About</a>
                </router-link>                
              </li>
              <li class="nav-item">
                <router-link to="/#functionalities" @click="scrollToSection">
                  <a href="#" class="nav-link">Functionalities</a>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="#examples" @click="scrollToSection">
                  <a href="#" class="nav-link">Examples</a>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="#reviews" @click="scrollToSection">
                  <a href="#" class="nav-link">Reviews</a>
                </router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {useRoute, useRouter} from 'vue-router';

export default {
  name: "Navbar",
  data() {
    return {
      isSticky: false,
      isMenu: false,
      active: false,
      button_active_state: false,
      logo: null,
      route: useRoute(),
      router: useRouter,
    };
  },
  methods: {
    scrollToSection() {
      this.active = false;
      this.button_active_state = false;
    },
    fetchImage(imgUrl) {
        return `${this.$baseUrl}${imgUrl}`
    },
  },
  mounted() {
    if (this.route.hash) {
      setTimeout(() => {
        this.$router.push(`/${this.route.hash}`);
        this.scrollToSection();
      }, 600);
    }
    const that = this;
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        that.isSticky = true;
      } else {
        that.isSticky = false;
      }
    });


  },
  created: async function () {
    const response = await axios.get(`${this.$baseUrl}/api/sitelogo?populate=deep`);
    const {
      data: { attributes },
    } = response.data;
    this.logo = attributes;
  },
};
</script>

<style scoped>
.navbar-brand img {
  height: 3rem;
}

@media only screen and (min-width: 1000px) {
  .navbar-brand {
    position: absolute;
  }

  .nav-border-bottom {
    border-bottom: 1px solid #B9D4FA;
  }
}
</style>